import React, { useEffect } from "react";
import { TextField, Select } from "@cpchem/covalence-ui";
import { CyResinTypes } from "../../../../interfaces/analysis-types";
import { handleNumericChange } from "../../../../utilities/report-analysis/handleNumericChange";

interface ResinInformationSectionProps {
    resinType: CyResinTypes;
    rho0: string;
    g0est: string;
    drdt: string;
    resinLabel: string;
    onResinTypeChange: (value: CyResinTypes) => void;
    onRho0Change: (value: string) => void;
    onG0estChange: (value: string) => void;
    onDrdtChange: (value: string) => void;
    onResinLabelChange: (value: string) => void;
    isDisabled?: boolean;
    testId?: string;
}

export function ResinInformationSection({
    resinType,
    rho0,
    g0est,
    drdt,
    resinLabel,
    onResinTypeChange,
    onRho0Change,
    onG0estChange,
    onDrdtChange,
    onResinLabelChange,
    isDisabled,
    testId
}: ResinInformationSectionProps): JSX.Element {
    const resinTypes = [
        { key: "PE", value: "PE", text: "PE" },
        { key: "PP", value: "PP", text: "PP" },
        { key: "PS", value: "PS", text: "PS" },
        { key: "Other", value: "Other", text: "Other" }
    ];
    useEffect(() => {
        // TODO: Replace with actual API call to fetch resin data
        const resinDataMap = {
            PE: {
                rho0: "0.86379",
                g0est: "0.0005126",
                drdt: "200000",
                resinLabel: "PE"
            },
            PP: {
                rho0: "0.90000",
                g0est: "0.0006000",
                drdt: "180000",
                resinLabel: "PE"
            },
            PS: {
                rho0: "0.95000",
                g0est: "0.0007000",
                drdt: "160000",
                resinLabel: "PE"
            },
            Other: { rho0: "", g0est: "", drdt: "", resinLabel: "" }
        };

        const resinData = resinDataMap[resinType as keyof typeof resinDataMap];
        onRho0Change(resinData.rho0);
        onG0estChange(resinData.g0est);
        onDrdtChange(resinData.drdt);
        onResinLabelChange(resinData.resinLabel);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [resinType]);

    return (
        <div className="resin-information-section" data-testid={testId}>
            <h4>Resin Selection</h4>
            <div
                className="viscosity-resin-parameter-section"
                data-testid={testId}
            >
                <h5>Resin Types</h5>
                <Select
                    className="resin-type-select"
                    label="Resin Type"
                    isLabelHidden
                    options={resinTypes}
                    value={resinType}
                    onChange={(value) =>
                        onResinTypeChange(value as CyResinTypes)
                    }
                    isDisabled={isDisabled}
                    testId={`${testId}-select`}
                />
            </div>

            <TextField
                label="Reference Melt Density (g/cm3)"
                value={rho0}
                onChange={(value) => handleNumericChange(value, onRho0Change)}
                isDisabled={isDisabled}
                testId={`${testId}-rho0`}
            />
            <TextField
                label="Plateau Modulus (Pa)"
                value={g0est}
                onChange={(value) => handleNumericChange(value, onG0estChange)}
                isDisabled={isDisabled}
                testId={`${testId}-g0est`}
            />
            <TextField
                label="Melt Density Delta (g/cm3/°C)"
                value={drdt}
                onChange={(value) => handleNumericChange(value, onDrdtChange)}
                isDisabled={isDisabled}
                testId={`${testId}-drdt`}
            />
            <TextField
                label="Resin Label"
                value={resinLabel}
                onChange={onResinLabelChange}
                isDisabled={isDisabled}
                testId={`${testId}-resin-label`}
            />
        </div>
    );
}
