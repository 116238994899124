import {
    AnalysisParameters,
    CyRheologyParameters,
    ViscosityParameters,
    ViscosityResinTypes
} from "../../../interfaces/analysis-types";
import {
    RheologyAnalysisRequest,
    RheologyViscositySample,
    RheologyViscositySampleMeasurements
} from "@services/space/analysis/cy-rheology/models";
import {
    ViscosityAnalysisRequest,
    ViscosityAnalysisSample
} from "@services/space/analysis/viscosity/models";
import { BaseAnalysisRequest } from "@services/space/analysis/base-models";
import { StoredDataset } from "@services/space/datasets/models";

const resinTypeDictionary: { [key: string]: string } = {
    Other: "OTH",
    PE: "PE",
    PP: "PP",
    PS: "PS"
};

const getMetricIndex = (
    metrics: { identifier: string | null }[],
    targetIdentifier: string
): number => {
    return metrics.findIndex(
        (metric) => metric.identifier === targetIdentifier
    );
};

const getMetadataValue = (
    dataset: StoredDataset,
    identifier: string
): string | undefined => {
    return dataset.metadata
        .find((meta) =>
            meta.attributes.some((attr) => attr.identifier === identifier)
        )
        ?.attributes.find((attr) => attr.identifier === identifier)?.value as
        | string
        | undefined;
};

const getSeriesData = (
    dataset: StoredDataset,
    identifier: string
): number[] => {
    const series = dataset.series.find((s) =>
        s.metrics.some((metric) => metric.identifier === identifier)
    );
    if (!series) {
        return [];
    }
    const index = getMetricIndex(series.metrics, identifier);
    return series.dataPoints.map((point) => parseFloat(point[index] as string));
};

const buildRheologySample = (
    dataset: StoredDataset,
    cyRheologyParams: CyRheologyParameters
): RheologyViscositySample => {
    const name = getMetadataValue(dataset, "sample_name") || dataset.fileName;
    const requestedBy = getMetadataValue(dataset, "sample_requested_by") || "";

    const resinTypeValue = cyRheologyParams.resinType;
    const resinType =
        resinTypeDictionary[
            resinTypeValue as keyof typeof resinTypeDictionary
        ] || resinTypeDictionary.Other;

    const { fileName } = dataset;

    const referenceTemperature = Number(
        cyRheologyParams.referenceTemp || "190.0"
    );

    const vogelTemperature = Number(cyRheologyParams.vogelTemp || "0.0");

    const measurements: RheologyViscositySampleMeasurements = {
        temperature: getSeriesData(dataset, "temperature"),
        angularFrequency: getSeriesData(dataset, "angular_frequency"),
        complexShearViscosity: getSeriesData(
            dataset,
            "complex_shear_viscosity"
        ),
        shearStorageModulus: getSeriesData(dataset, "shear_storage_modulus"),
        shearLossModulus: getSeriesData(dataset, "shear_loss_modulus")
    };

    return {
        name,
        requestedBy,
        resinType,
        fileName,
        referenceTemperature,
        vogelTemperature,
        measurements
    };
};

const buildViscositySample = (
    dataset: StoredDataset,
    viscosityParams: ViscosityParameters
): ViscosityAnalysisSample => {
    const name = getMetadataValue(dataset, "sample_name") || dataset.fileName;
    const requestedBy = getMetadataValue(dataset, "sample_requested_by") || "";
    const { fileName } = dataset;

    const resinTypeValue = viscosityParams.resinType as ViscosityResinTypes;
    const resinType =
        resinTypeDictionary[resinTypeValue] || resinTypeDictionary.Other;

    const referenceTemperature = Number(
        viscosityParams.cyRheologyParams.referenceTemp || "190.0"
    );
    const applyYieldStressTerm =
        viscosityParams.cyRheologyParams.isUsingYieldStressTerm || false;
    const fixedN = viscosityParams.cyRheologyParams.fixN
        ? parseFloat(viscosityParams.cyRheologyParams.nValue || "0")
        : null;

    const measurements = {
        temperature: getSeriesData(dataset, "temperature"),
        angularFrequency: getSeriesData(dataset, "angular_frequency"),
        complexShearViscosity: getSeriesData(
            dataset,
            "complex_shear_viscosity"
        ),
        shearStorageModulus: getSeriesData(dataset, "shear_storage_modulus"),
        shearLossModulus: getSeriesData(dataset, "shear_loss_modulus")
    };

    return {
        name,
        requestedBy,
        resinType,
        fileName,
        referenceTemperature,
        applyYieldStressTerm,
        fixedN,
        measurements
    };
};

export const buildRheologyAnalysisRequest = (
    cyRheologyParams: CyRheologyParameters,
    selectedDatasets: StoredDataset[]
): RheologyAnalysisRequest => {
    const isTTS = cyRheologyParams.dataType === "tempFreqSweep";
    return {
        analysisType: isTTS ? "cyRheologyTTS" : "cyRheology",
        applyYieldStressTerm: cyRheologyParams.isUsingYieldStressTerm || false,
        fixedN: cyRheologyParams.fixN
            ? parseFloat(cyRheologyParams.nValue || "0")
            : null,
        samples: selectedDatasets.map((dataset: StoredDataset) => {
            return buildRheologySample(dataset, cyRheologyParams);
        })
    };
};

export const buildViscosityAnalysisRequest = (
    viscosityParams: ViscosityParameters
): ViscosityAnalysisRequest => {
    const selectedDatasetGroups = viscosityParams.selectedDatasetGroups || [];

    const sampleGroups = selectedDatasetGroups.map((group) => {
        const samples = group.group.map((dataset) =>
            buildViscositySample(dataset, viscosityParams)
        );
        return {
            name: group.name,
            samples
        };
    });

    return {
        analysisType: "viscosity",
        sampleGroups
    };
};

export const buildAnalysisRequest = async (
    analysisParams: AnalysisParameters
): Promise<BaseAnalysisRequest | ViscosityAnalysisRequest> => {
    switch (analysisParams.analysisType) {
        case "cyRheology":
            if (analysisParams.cyRheologyParams) {
                return buildRheologyAnalysisRequest(
                    analysisParams.cyRheologyParams,
                    analysisParams.cyRheologyParams.selectedDatasets || []
                );
            }
            throw new Error(
                "cyRheologyParams are required for cyRheology analysis."
            );

        case "viscosity":
            return buildViscosityAnalysisRequest(
                analysisParams.viscosityParams!
            );
        default:
            throw new Error(
                `Unsupported analysis type: ${analysisParams.analysisType}`
            );
    }
};
