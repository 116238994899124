import React, { useCallback } from "react";
import { DatasetsSelector } from "../../datasets-selector/datasets-selector";
import { localStorageService } from "../../../../utilities/dataset-page/localStorageService";
import { StoredDataset } from "@services/space/datasets/models";
import { useAuth } from "../../../../hooks/use-auth";

interface DatasetsSectionProps {
    selectedDatasets: StoredDataset[];
    onUpdateSelectedDatasets: (datasets: StoredDataset[]) => void;
    isLoading?: boolean;
    testId?: string;
}

export function DatasetsSection({
    selectedDatasets,
    onUpdateSelectedDatasets,
    isLoading,
    testId
}: DatasetsSectionProps): JSX.Element {
    const { currentAccount } = useAuth();
    const currentUserId = currentAccount?.username;
    const userUploadedDatasets = localStorageService.loadDatasetsForUser(
        currentUserId as string
    );

    const handleDatasetsSelection = useCallback(
        (datasetNames: string[]) => {
            const datasetsToAdd = userUploadedDatasets?.filter((d) =>
                datasetNames.includes(d.fileName)
            );

            const newDatasets = datasetsToAdd?.filter(
                (d) =>
                    !selectedDatasets.some((sd) => sd.fileName === d.fileName)
            );

            if (newDatasets && newDatasets.length > 0) {
                onUpdateSelectedDatasets([...selectedDatasets, ...newDatasets]);
            }
        },
        [selectedDatasets, onUpdateSelectedDatasets, userUploadedDatasets]
    );

    const handleDatasetDismissal = useCallback(
        (datasetName: string) => {
            onUpdateSelectedDatasets(
                selectedDatasets.filter((d) => d.fileName !== datasetName)
            );
        },
        [selectedDatasets, onUpdateSelectedDatasets]
    );

    const handleDatasetReorder = useCallback(
        (updatedDatasets: StoredDataset[]) => {
            onUpdateSelectedDatasets(updatedDatasets);
        },
        [onUpdateSelectedDatasets]
    );

    return (
        <DatasetsSelector
            selectedDatasets={selectedDatasets}
            onSelectDatasets={handleDatasetsSelection}
            onHandleDismissDataset={handleDatasetDismissal}
            onHandleDatasetReorder={handleDatasetReorder}
            hasTitle
            isLoading={isLoading}
            testId={testId}
        />
    );
}
