import React from "react";
import { Checkbox } from "@cpchem/covalence-ui";
import { TableOfCyParameters } from "../../../../interfaces/table-of-cy-parameters";

interface TableOfCyParametersSectionProps {
    tableOfCyParameters: TableOfCyParameters[];
    onTableOfCyParametersChange: (value: TableOfCyParameters[]) => void;
    isDisabled?: boolean;
    testId?: string;
}

export function TableOfCyParametersSection({
    tableOfCyParameters,
    onTableOfCyParametersChange,
    isDisabled,
    testId
}: TableOfCyParametersSectionProps): JSX.Element {
    // TODO: Replace with actual API call to fetch CY parameters
    const cyParametersOptions: { label: string; value: TableOfCyParameters }[] =
        [
            {
                label: "CY Crossover Freq - Carrreau-Yasuda Crossover Frequency",
                value: "CY Crossover Freq - Carrreau-Yasuda Crossover Frequency"
            },
            {
                label: "CY Zero Shear Viscosity - Carrreau-Yasuda Zero Shear Viscosity",
                value: "CY ETA_0 - Carreau-Yasuda Zero Shear Viscosity"
            }
        ];

    const handleCheckboxChange = (
        parameter: TableOfCyParameters,
        isChecked: boolean
    ) => {
        let updatedParameters: TableOfCyParameters[];
        if (isChecked) {
            updatedParameters = [...tableOfCyParameters, parameter];
        } else {
            updatedParameters = tableOfCyParameters.filter(
                (param) => param !== parameter
            );
        }
        onTableOfCyParametersChange(updatedParameters);
    };

    return (
        <div className="cy-parameters-section" data-testid={testId}>
            <h4>Cy Parameters</h4>
            <div className="cy-parameters-items">
                {cyParametersOptions.map((option) => (
                    <Checkbox
                        key={option.value}
                        label={option.label}
                        isChecked={tableOfCyParameters.includes(option.value)}
                        onChange={(checked) =>
                            handleCheckboxChange(option.value, checked)
                        }
                        isDisabled={isDisabled}
                        testId={`${testId}-checkbox-${option.value}`}
                    />
                ))}
            </div>
        </div>
    );
}
