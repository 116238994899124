import React, { useState, useEffect } from "react";
import { BaseCyRheologyForm } from "../cy-rheology/base-cy-rheology-form";
import { DataTypeSection } from "../cy-rheology/components/data-type-section";
import { FixNSection } from "../cy-rheology/components/fix-n-section";
import { ReferenceTempSection } from "../cy-rheology/components/reference-temp-section";
import { YieldStressTermSection } from "../cy-rheology/components/yield-stress-term-section";
import {
    ViscosityParameters,
    CyRheologyParametersForViscosityAnalysis
} from "../../../interfaces/analysis-types";
import { ValidateCyRheologyParametersForViscosity } from "./viscosity-validations";
import { SpaceAccordion } from "../../space-accordion/space-accordion";
import { Divider } from "@cpchem/covalence-ui";
import { DatasetsSection } from "../cy-rheology/components/datasets-section";
import { ViscosityParametersSection } from "./components/viscosity-parameters-section";
import "../styles.scss";
import { ValidateDatasets } from "../cy-rheology/cy-rheology-validations";

interface ViscosityFormProps {
    initialParameters: ViscosityParameters;
    onUpdate: (updates: Partial<ViscosityParameters>) => void;
    onValidation: (isValid: boolean) => void;
    isLoading?: boolean;
    testId?: string;
}

export function ViscosityForm({
    initialParameters,
    onUpdate,
    onValidation,
    isLoading,
    testId
}: ViscosityFormProps): JSX.Element {
    const [openAccordion, setOpenAccordion] = useState<string | null>(
        "Datasets"
    );
    const [parameters, setParameters] =
        useState<ViscosityParameters>(initialParameters);

    const [isDatasetsValid, setIsDatasetsValid] = useState<boolean>(false);
    const [isRheologyValid, setIsRheologyValid] = useState<boolean>(false);
    const [isViscosityParamsValid, setIsViscosityParamsValid] =
        useState<boolean>(false);

    useEffect(() => {
        const isValid =
            isDatasetsValid && isRheologyValid && isViscosityParamsValid;
        onValidation(isValid);
    }, [
        isDatasetsValid,
        isRheologyValid,
        isViscosityParamsValid,
        onValidation
    ]);

    const handleUpdate = (updates: Partial<ViscosityParameters>) => {
        const updatedParameters = { ...parameters, ...updates };
        setParameters(updatedParameters);
        onUpdate(updatedParameters);
    };

    useEffect(() => {
        const isValid = ValidateDatasets(parameters.selectedDatasets.length);
        setIsDatasetsValid(isValid);
    }, [parameters.selectedDatasets]);

    return (
        <div className="parameters-content viscosity-form">
            <SpaceAccordion
                label="Datasets"
                isOpen={openAccordion === "Datasets"}
                onToggle={() =>
                    setOpenAccordion(
                        openAccordion === "Datasets" ? null : "Datasets"
                    )
                }
                defaultOpen
                testId={`${testId}-datasets-accordion`}
            >
                <DatasetsSection
                    selectedDatasets={parameters.selectedDatasets}
                    onUpdateSelectedDatasets={(datasets) =>
                        handleUpdate({ selectedDatasets: datasets })
                    }
                    isLoading={isLoading}
                    testId={`${testId}-datasets-section`}
                />
            </SpaceAccordion>
            <Divider />

            <SpaceAccordion
                label="Rheology Parameters"
                isOpen={openAccordion === "Rheology Parameters"}
                onToggle={() =>
                    setOpenAccordion(
                        openAccordion === "Rheology Parameters"
                            ? null
                            : "Rheology Parameters"
                    )
                }
                testId={`${testId}-rheology-parameters-accordion`}
            >
                <BaseCyRheologyForm<CyRheologyParametersForViscosityAnalysis>
                    initialParameters={parameters.cyRheologyParams}
                    onUpdate={(updates) =>
                        handleUpdate({
                            cyRheologyParams: {
                                ...parameters.cyRheologyParams,
                                ...updates
                            }
                        })
                    }
                    onValidation={setIsRheologyValid}
                    isLoading={isLoading}
                    validate={ValidateCyRheologyParametersForViscosity}
                >
                    {({
                        parameters: rheologyParams,
                        handleFieldUpdate,
                        isLoading
                    }) => (
                        <>
                            <DataTypeSection
                                dataType={rheologyParams.dataType}
                                onChange={(value) =>
                                    handleFieldUpdate("dataType", value)
                                }
                                isDisabled={isLoading}
                            />
                            <FixNSection
                                fixN={rheologyParams.fixN}
                                nValue={rheologyParams.nValue}
                                onFixNChange={(value) =>
                                    handleFieldUpdate("fixN", value)
                                }
                                onNValueChange={(value) =>
                                    handleFieldUpdate("nValue", value)
                                }
                                isDisabled={isLoading}
                            />
                            <ReferenceTempSection
                                referenceTemp={rheologyParams.referenceTemp}
                                onChange={(value) =>
                                    handleFieldUpdate("referenceTemp", value)
                                }
                                isDisabled={isLoading}
                            />
                            <YieldStressTermSection
                                isUsingYieldStressTerm={
                                    rheologyParams.isUsingYieldStressTerm
                                }
                                onChange={(checked) =>
                                    handleFieldUpdate(
                                        "isUsingYieldStressTerm",
                                        checked
                                    )
                                }
                                isDisabled={isLoading}
                            />
                        </>
                    )}
                </BaseCyRheologyForm>
            </SpaceAccordion>
            <Divider />
            <SpaceAccordion
                label="Viscosity Parameters"
                isOpen={openAccordion === "Viscosity Parameters"}
                onToggle={() =>
                    setOpenAccordion(
                        openAccordion === "Viscosity Parameters"
                            ? null
                            : "Viscosity Parameters"
                    )
                }
                testId={`${testId}-viscosity-parameters-accordion`}
            >
                <ViscosityParametersSection
                    parameters={parameters}
                    onUpdate={(updates) => handleUpdate(updates)}
                    onValidation={setIsViscosityParamsValid}
                    isLoading={isLoading}
                    testId={`${testId}-viscosity-parameters-section`}
                />
            </SpaceAccordion>
        </div>
    );
}
