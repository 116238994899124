import React, { useState } from "react";
import { StoredDataset } from "@services/space/datasets/models";
import { SelectedDatasetsContainer } from "./components/selected-datasets-container";
import { Button } from "@cpchem/covalence-ui";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/pro-solid-svg-icons";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { DatasetsSelectorModal } from "./components/datasets-selector-modal";
import "./styles.scss";

export interface DatasetsSelectorProps {
    className?: string;
    hasTitle?: boolean;
    isLoading?: boolean;
    selectedDatasets: StoredDataset[];
    onSelectDatasets: (datasetNames: string[]) => void;
    onHandleDismissDataset: (fileName: string) => void;
    onHandleDatasetReorder: (updatedDatasets: StoredDataset[]) => void;
    testId?: string;
}

export function DatasetsSelector({
    className,
    hasTitle,
    isLoading,
    selectedDatasets,
    onSelectDatasets,
    onHandleDismissDataset,
    onHandleDatasetReorder,
    testId
}: DatasetsSelectorProps) {
    const cnParts = ["datasets-selector"];

    const [isDataSelectorModalOpen, setIsDataSelectorModalOpen] =
        useState(false);

    const handleDismissDataset = (fileName: string) => {
        onHandleDismissDataset(fileName);
    };

    if (className) {
        cnParts.push(className);
    }

    const DatasetsTitle = `${selectedDatasets.length} Datasets Included`;

    const selectedDatasetsTestId = testId ? `${testId}-datasets` : undefined;
    const cn = cnParts.join(" ");
    return (
        <>
            <div className={cn}>
                {hasTitle && <div className="title">{DatasetsTitle}</div>}
                <Button
                    className="add-dataset-button"
                    text="Add Dataset"
                    color="primary"
                    icon={<FontAwesomeIcon icon={faPlus as IconProp} />}
                    isIconAfterText
                    onClick={() =>
                        setIsDataSelectorModalOpen(!isDataSelectorModalOpen)
                    }
                />
            </div>
            <DatasetsSelectorModal
                className="datasets-selector-modal"
                isOpen={isDataSelectorModalOpen}
                selectedDatasets={selectedDatasets}
                onClose={() =>
                    setIsDataSelectorModalOpen(!isDataSelectorModalOpen)
                }
                onSelectDatasets={onSelectDatasets}
            />
            <SelectedDatasetsContainer
                selectedDatasets={selectedDatasets}
                handleDismissDataset={handleDismissDataset}
                handleDatasetReorder={onHandleDatasetReorder}
                testId={selectedDatasetsTestId}
                isLoading={isLoading}
            />
        </>
    );
}
