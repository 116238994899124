import {
    CyRheologyParametersForViscosityAnalysis,
    ViscosityParameters
} from "../../../interfaces/analysis-types";
import {
    ValidateDataType,
    ValidateNValue,
    ValidateReferenceTemp,
    ValidateDatasets
} from "../cy-rheology/cy-rheology-validations";

export function ValidateCyRheologyParametersForViscosity(
    params: CyRheologyParametersForViscosityAnalysis
): boolean {
    return (
        ValidateDataType(params.dataType) &&
        ValidateNValue(params.fixN, params.nValue) &&
        ValidateReferenceTemp(params.referenceTemp)
    );
}

export function ValidateViscosityForm(params: ViscosityParameters): boolean {
    const isDatasetsValid = ValidateDatasets(params.selectedDatasets.length);
    const isCyRheologyValid = ValidateCyRheologyParametersForViscosity(
        params.cyRheologyParams
    );
    const isViscosityParamsValid =
        params.submitter.trim() !== "" && params.description.trim() !== "";

    return isDatasetsValid && isCyRheologyValid && isViscosityParamsValid;
}
