import React, { useEffect } from "react";
import {
    CyResinTypes,
    ViscosityParameters
} from "../../../../interfaces/analysis-types";
import { ReportInformationSection } from "./report-information-section";
import { ResinInformationSection } from "./resin-information-section";
import { TableOfCyParametersSection } from "./table-Of-Cy-Parameters-section";

interface ViscosityParametersSectionProps {
    parameters: ViscosityParameters;
    onUpdate: (updates: Partial<ViscosityParameters>) => void;
    onValidation: (isValid: boolean) => void;
    isLoading?: boolean;
    testId?: string;
}

export function ViscosityParametersSection({
    parameters,
    onUpdate,
    onValidation,
    isLoading,
    testId
}: ViscosityParametersSectionProps): JSX.Element {
    const handleFieldUpdate = <K extends keyof ViscosityParameters>(
        field: K,
        value: ViscosityParameters[K]
    ) => {
        onUpdate({ [field]: value });
    };

    useEffect(() => {
        const isValid =
            (parameters.submitter?.trim() ?? "") !== "" &&
            (parameters.description?.trim() ?? "") !== "";
        onValidation(isValid);
    }, [parameters.submitter, parameters.description, onValidation]);

    return (
        <div className="viscosity-parameters-section">
            <ReportInformationSection
                submitter={parameters.submitter}
                description={parameters.description}
                onSubmitterChange={(value) =>
                    handleFieldUpdate("submitter", value)
                }
                onDescriptionChange={(value) =>
                    handleFieldUpdate("description", value)
                }
                isDisabled={isLoading}
                testId={`${testId}-report-information`}
            />
            <ResinInformationSection
                resinType={parameters.resinType}
                rho0={parameters.rho0}
                g0est={parameters.g0est}
                drdt={parameters.drdt}
                resinLabel={parameters.resinLabel}
                onResinTypeChange={(value: CyResinTypes) =>
                    handleFieldUpdate("resinType", value)
                }
                onRho0Change={(value: string) =>
                    handleFieldUpdate("rho0", value)
                }
                onG0estChange={(value: string) =>
                    handleFieldUpdate("g0est", value)
                }
                onDrdtChange={(value: string) =>
                    handleFieldUpdate("drdt", value)
                }
                onResinLabelChange={(value: string) =>
                    handleFieldUpdate("resinLabel", value)
                }
                isDisabled={isLoading}
                testId={`${testId}-resin-information`}
            />
            <TableOfCyParametersSection
                tableOfCyParameters={parameters.tableOfCyParameters}
                onTableOfCyParametersChange={(value) =>
                    handleFieldUpdate("tableOfCyParameters", value)
                }
                isDisabled={isLoading}
                testId={`${testId}-cy-parameters`}
            />
        </div>
    );
}
